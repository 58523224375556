@import "global.scss";

div.user-profile-container {
    min-height: 30vh;
    min-width: 350px;
    width: 90vw;

    border: solid 2px $base;
    border-radius: 3px;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;

    position: relative;

    padding: 5px;
    text-align: center;
    justify-content: space-between;

    h4.title {
        // text-align: left;
        border-bottom: solid 1px $background-gray;
    }
    div.loading-wrapper {
        flex: 1;
    }

    div.profile-content {
        .tabs-wrapper {
            ul.nav-tabs {
                display: flex;
                flex-direction: row-reverse;
            }
        }

        .avatar {
            max-width: 200px;
            width: 100%;
        }
    }

    // Tabs
    div.about-wrapper {
        div.name-and-org {
            .full-name {
                flex: 1;
                font-size: 1.25rem;
            }
            .disp-org {
                display: inline-flex;
                column-gap: 5px;
                align-items: center;
                a {
                    color: $base;
                    text-decoration: underline;
                    &:hover {
                        font-weight: bold;
                    }
                }
            }
        }
        div.details {
            p.switch-view {
                margin: 0; padding: 0;
                font-size: 12px;
                line-height: 14px;
                font-style: italic;
            }
        }
    }
}
