@import 'global.scss';

$HEADLINE-HEIGHT: 36px;

.problem-table {
  position: relative;

  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4, h5 {
    margin: unset;
    padding: 4px;
    height: $HEADLINE-HEIGHT;

    font-size: medium;
    font-weight: bold;
    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;

    &.problem-code {
      min-width: fit-content;
      max-width: 50px;
    }
    &.problem-title {
      min-width: fit-content;
      max-width: 100px;
    }
  }
  table {
    margin: unset;
  }

  .problem-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;

  }
  //
  div.title-section {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: center;

    div.problem-code {
      font-family: $font-codeforces;
      font-size: 18px;
      display: flex;

      align-items: center;
      column-gap: 3px;

      span.visibility-tag {
        border: solid 1px $background-gray-dark;
        border-radius: 10px;
        padding: 1px 4px;

        display: flex;
        justify-items: center;
        align-items: center;

        margin: 0 5px;
      }
    }
    div.problem-title {
      font-size: 14px;
    }
  }

  div.solve-section {
    display: flex;
    flex-direction: column;
    text-align: right;

    .ac-count {
      font-size: 14px;
      white-space: nowrap;
      display: inline-flex;
      justify-content: flex-end;

      color: $accepted;
      font-family: $font-codeforces;
      font-weight: bold;
    }
    .ac-rate {
      white-space: nowrap;
      display: inline-flex;
      justify-content: flex-end;

      font-family: $font-codeforces;
      font-weight: bold;
    }
  }

  .problem-count-text {
    position: absolute;
    left: 5px;
    top: 5px;

    .count-text {
      font-size: 14px;
      .number {
        font-family: $font-monospace;
        font-weight: bold;
      }
    }
  }

}
