@import "global.scss";

$filter-width: 600px;
$filter-height: 230px;

div#standing-filter {
    width: $filter-width;
    height: $filter-height;
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    .filter-col {
        display: flex;
        flex-direction: column;
    }

    .org-filter {
        width: 100%;
        height: 85%;
    }

    .filter-label {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin: 10px 0;

        &:first-child {
            margin-bottom: 10px;
            margin-top: 0px;
        }

        input {
            margin-right: 2px;
        }

        label {
            margin-bottom: 0;
            display: inline-flex;
            align-items: center;

            span {
                margin-left: 4px;
                margin-right: 8px;
            }
        }
    }

    .filter-container {
        height: 85%;
        overflow: auto;
        overflow-x: clip;
        padding-left: 6px;

    }

    .filter-option {
        margin: 4px 0;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }

    .filter-control-btn {
        display: flex;
        margin: 4px 0;

        button {
            margin-right: 4px;
        }
    }

    .clear-icon {
        cursor: pointer;
        color: red;
    }
}