@import 'global.scss';

$HEADLINE-HEIGHT: 36px;

.submission-table {
  position: relative;

  .submission-table-control {
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: row;

    button {
      margin: 3px 4px 2px 0;
      height: calc($HEADLINE-HEIGHT - 10px);
      min-width: calc($HEADLINE-HEIGHT - 10px);
    }
  }

  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4, h5 {
    height: $HEADLINE-HEIGHT;
    margin: unset;
    padding: 7px;

    font-size: medium;
    font-weight: bold;
    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;
  }
  table {
    margin: unset;
  }

  .submission-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;
  }

  // General Info
  td.general-info {
    padding: 0;
    margin: 0;
    min-width: 100px;
    max-width: 300px;

    div.general-info-container {
      width: 100%;
      padding: 0 5px;
      display: flex;
      flex-direction: column;

      * {
        text-align: left;
      }

      span.author {
        font-size: 12px;
      }
      span.language {
        margin-left: 2px;
        font-size: 12px;
      }
    }
  }

  // Submission item
  td.verdict {
    padding: 0;
    margin: 0;
    div.verdict-container {
      width: 100%;
      min-width: 170px;
      min-height: 50px;
      display: inline-flex;
      flex-direction: row;

      align-items: center;
      justify-content: center;

      div.verdict-wrapper {
        flex: 1;
      }
      div.points-container {
        // height: 0;
        // width: 30%; padding-bottom: 30%;
        height: 100px; width: 100px;
        max-height: 60px; max-width: 60px;
        cursor: help;

        position: relative;
        // border: solid 1px green;
        &.available {
          background: linear-gradient(to top left,
            rgba(0,0,0,0.05) 0%,
            rgba(0,0,0,0.05) calc(50% - 0.8px),
            rgba(0,0,0,0.2) 50%,
            rgba(0,0,0,0.05) calc(50% + 0.8px),
            rgba(0,0,0,0.05) 100%);
          span.sub-points {
            max-width: 75%;
            position: absolute; top: 10%; left: 10%;
          }
          span.prob-points {
            max-width: 75%;
            color: black;
            position: absolute; bottom: 10%; right: 10%;
          }
        }
      }
    }
  }

  // Resource Usage
  td.resource-allocated {
    padding: 0;
    margin: 0;
    // background-color: rgba(0,0,0,0.05);
    min-width: 100px;

    div.resource-allocated-container {
      padding: 0 5px;
      span.cpu-usage {
        font-weight: bold;
      }
    }
  }

  tr.spectator-submission {
    background-color: rgba(100, 200, 255, 0.2);

    &:hover {
      background-color: rgba(100, 200, 255, 0.25);
    }

    &:nth-of-type(odd) {
      background-color: rgba(100, 200, 255, 0.3);

      &:hover {
        background-color: rgba(100, 200, 255, 0.35);
      }
    }
  }

  .submission-count-text {
    position: absolute;
    left: 5px;
    top: 5px;
    height: 26px;

    .count-text {
      font-size: 14px;
      .number {
        font-family: $font-monospace;
        font-weight: bold;
      }
    }
  }

  @import 'styles/SubmissionVerdict.scss';
}
