@import "global.scss";

div.rich-text-editor {
  width: 100%;
  * {
    width: unset;
  }

  div.w-md-editor {
    div.w-md-editor-toolbar {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      padding-right: 3px;
      min-width: 410px;

      ul {
        display: inline-flex;
        column-gap: 2px;
        margin: unset;

        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;
        }

        li {
          height: 20px;
          width: 20px;
          padding: unset;

          &:empty { height: 0; width: 0; }

          button {
            margin: unset;
            padding: unset;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;

            svg {
              width: 100%;
            }
          }
        }
      }
    }
    div.w-md-editor-content {
      display: flex;
      .w-md-editor-input {
        width: unset;
      }
      .w-md-editor-aree {
        border-radius: 5px;
        overflow: auto;
      }
      .w-md-editor-preview {
        width: unset;
        border-radius: 0 0 5px 0;
        bottom: 0;
        box-shadow: inset 1px 0 0 0 var(--color-border-default);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 10px 20px;
        position: absolute;
        right: 0;
        top: 0;
      }
      div {
        display: block;
      }
      textarea.w-md-editor-text-input {
        width: 100%;
      }
    }

    div.w-md-editor-bar {
      background-color: $sub;
      border-radius: 0 0 3px 0;
      cursor: s-resize;
      height: 10px;
      margin-right: 0;
      margin-top: -11px;
      position: absolute;
      right: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 14px;
      z-index: 3;
    }
  }

  div.w-md-editor-show-edit {
    div.w-md-editor-content {
      .w-md-editor-input { width: 100%; }
      .w-md-editor-preview { width: 0; }
    }
  }
  div.w-md-editor-show-live {
    div.w-md-editor-content {
      .w-md-editor-input { width: 50%; }
      .w-md-editor-preview { width: 50%; }
    }
  }
  div.w-md-editor-show-preview {
    div.w-md-editor-content {
    .w-md-editor-input { width: 0; }
    .w-md-editor-preview { width: 100%; }
    }
  }

  div.wmde-markdown-color {
    * {
      width: unset;
      color: unset;
      overflow: unset;
    }
    code {
      span.katex {
        font-size: 16px;
        display: inline;
      }
      span {
        width: unset;
      }
      &.code-highlight, &.undefined {
        padding: 0 10px;
        background-color: var(--color-neutral-muted);
      }
    }
    span {
      text-align: left;
    }
    span.token {
      display: inline;
    }
  }
  textarea {
    white-space: pre-wrap;
    word-break: break-word;
  }
}
