@import 'global.scss';

div.participant-container {
  column-gap: 10px;
  row-gap: 10px;

  div.avatar-container {
    height: 50px;
    width: auto;
    max-width: 50px;

    display: flex;
    align-items: center;

    .avatar-img {
      // height: 100%;
      // max-width: 50px;
      display: block;
      max-width: 50px;
      max-height: 50px;
      width: auto;
      height: auto;
    }
  }

  div.user-container {
    align-items: baseline;
    width: calc(100% - 50px);

    div.acc-username {
      font-family: $font-codeforces;
      font-size: 14px;
      max-width: 140px;
      width: 100%;
      text-align: left;
      cursor: help;
    }

    div.acc-realname {
      width: 100%;
      font-size: 12px;
      text-align: left;
    }

    div.acc-org {
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      cursor: help;
    }
  }

  div.participant-name-wrapper {
    display: inline-flex;
    align-items: center;
  }

  svg.favorite-icon {
    cursor: pointer;
  }
}
