@import 'global.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background-color: $background-gray;
  // min-height: 800px;
  background-color: $base;
  min-width: 400px;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding-left: 3px;
  padding-right: 3px;
}

h1, h2, h3, h4, h5 {
  color: $base;
}

a svg {
  vertical-align: middle;
}

button.btn-svg {
  display: flex;
  align-items: center;
  justify-items: center;
  column-gap: 5px;
  min-height: 30px;
}

label.required {
  white-space: pre;
  font-weight: bold;
  &::after {
    content: "*";
    color: red;
  }
}

div.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
div.flex-center-col {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

div.wrapper-vanilla {
  margin: unset;
  padding: unset;
  border: solid 2px $base;
  background-color: $sub;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
  border-radius: 0.25rem !important;
}

.text-truncate-rv {
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  width: 100%;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.6);
  border-radius: 10px;
  // background: $base;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px $base;
  background: $background-gray;
}

// Toastify
.Toastify__toast-body {
  font-family: $font-codeforces;
  font-size: 14px;
}
.Toastify__toast--error {
  background: $red-lighter !important;
}
.Toastify__toast--success {
  background: $blue-lighter !important;
}
.Toastify__toast--warning {
  background: $orange-lighter !important;
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/_buttons.scss";
.btn-dark {
  @include button-variant($base, darken($base, 7.5%), darken($base, 10%), lighten($base,5%), lighten($base, 10%), darken($base,30%));
  color: $sub;
  &:hover, &:focus {
    color: $sub;
  }
}
.btn-outline-dark {
  @include button-outline-variant($base, #222222, lighten($base,5%), $base);
  color: $sub;
}
.btn-light {
  @include button-variant($sub, darken($sub, 7.5%), darken($sub, 10%), lighten($sub,5%), lighten($sub, 10%), darken($sub,30%));
  color: $base;
  &:hover, &:focus {
    color: $base;
  }
}
.btn-outline-light {
  @include button-outline-variant($sub, #222222, lighten($sub,5%), $sub);
  color: $base;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.loading_3dot::after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

//
.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */

.style-as-default-link {
  color:#007bff !important;
  &:hover {
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer !important;
  }
}