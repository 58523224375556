@import 'global.scss';

div#org-main {
    div#org-title-div {
        min-height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 3px 0;

        row-gap: 5px;

        div.org-path {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 10px;
            align-items: center;

            div.org-path-item {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                // max-width: 180px;

                border: solid 2px rgba(0, 0, 0, .1);
                padding: 2px 5px;
                border-radius: 5px;

                img.org-path-item-img {
                    // height: 30px;
                    width: auto;
                    margin-right: 10px;
                }
                span.org-path-item-slug {
                    font-family: $font-brand;
                    font-weight: bold;
                }
            }
        }
    }

    div.org-table {
        div.org-name-wrapper {
            // margin-left: 10px;
            text-align: left;

            h6 {
                font-family: $font-brand;
                font-weight: bold;
                margin: 0;
            }
        }
    }

    div.org-detail-wrapper-col {
        div.org-detail-wrapper {
            height: 100%;
            display: flex;
            align-self: baseline;
            flex-direction: column;

            // row-gap: 10px;

            margin-right: 4px;
            padding-right: 4px;
            overflow-y: auto;

            h5.org-detail-title {
                font-family: $font-brand;
                font-weight: bold;
                font-size: x-large;
            }

            div.org-detail-item {
                word-break: break-word;
                margin: 6px;
                padding: 4px;
                text-align: left;

                &:first-child { margin-top: 0; }
                &:last-child { margin-bottom: 0; }
            }
        }
    }

    div.org-table-wrapper-col {
        div.org-table-wrapper {
            max-height: 60vh;
            display: block;
            overflow: auto;
        }
    }
}