@import 'global.scss';

div#contest-app {
    position: relative;
    width: 95vw;

    div#contest-banner {
        hr.divisor {
            margin: 0;
            width: 100%;
        }
        #header {
            background-color: $background-gray-dark;
            font-family: $font-codeforces;
            font-weight: bold;
            font-size: 1.3rem;
            padding: 5px;
            margin: unset;
        }
        #contest-name {
            padding: 5px;
            font-family: $font-codeforces;
            font-weight: bold;
            font-size: 1.6rem;
            color: $base !important;
        }
        #contest-time {
            padding: 4px;
            font-size: 0.9rem;
            font-family: $font-codeforces;
            font-weight: bolder;
            color: $blue-dark;
        }
    }

    div#contest-app-nav {
        width: 100%;
        column-gap: 10px;
        padding: 0 15px;

        border: unset;
        background-color: unset;

        a {
            font-size: 14px;
            font-family: $font-codeforces;
            text-transform: capitalize;
            color: $base;

            &:hover {
                text-decoration: underline;
            }
            &.active {
                font-weight: bold;
            }
        }
    }
}
