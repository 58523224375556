@import 'global.scss';

.admin-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background-color: $background-gray !important;

  #admin-content-wrapper {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-content: start;

    #admin-topbar {
      background-color: $sub !important;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      column-gap: 15px;

      border-bottom: solid 2px $background-gray-dark;
      z-index: 1050;

      position: -webkit-sticky;
      position: sticky;
      top: 0;

      #sidebar-toggle-btn {
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        line-height: 1.75rem;
        // font-size: 0.7rem;
        // border: 2px solid $sub-dark
      }
    }

    #admin-panel-container {
      // margin-top: 30px;
      // margin-bottom: 30px;
      // padding-top: 30px;
      // padding-bottom: 30px;
      min-height: 90vh;
      padding: 20px;
      overflow: auto;
    }
  }

  form {
    text-align: left;
    margin: 4px;

    .row {
      border: solid 1px $background-gray;
      margin: 2px;
      padding: 2px 0;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      display: flex;
      align-self: center;

      sub {
        padding-top: 5px;
        font-style: italic;
        bottom: unset;
        line-height: 1;
      }

      // * {
      //   width: 100%;
      // }
    }

    input::placeholder {
      font-style: italic;
      color: $background-gray-dark;
    }

    label {
      &.col, &.col-1, &.col-10, &.col-11, &.col-12, &.col-2, &.col-3, &.col-4, &.col-5, &.col-6, &.col-7, &.col-8, &.col-9, &.col-auto, &.col-lg, &.col-lg-1, &.col-lg-10, &.col-lg-11, &.col-lg-12, &.col-lg-2, &.col-lg-3, &.col-lg-4, &.col-lg-5, &.col-lg-6, &.col-lg-7, &.col-lg-8, &.col-lg-9, &.col-lg-auto, &.col-md, &.col-md-1, &.col-md-10, &.col-md-11, &.col-md-12, &.col-md-2, &.col-md-3, &.col-md-4, &.col-md-5, &.col-md-6, &.col-md-7, &.col-md-8, &.col-md-9, &.col-md-auto, &.col-sm, &.col-sm-1, &.col-sm-10, &.col-sm-11, &.col-sm-12, &.col-sm-2, &.col-sm-3, &.col-sm-4, &.col-sm-5, &.col-sm-6, &.col-sm-7, &.col-sm-8, &.col-sm-9, &.col-sm-auto, &.col-xl, &.col-xl-1, &.col-xl-10, &.col-xl-11, &.col-xl-12, &.col-xl-2, &.col-xl-3, &.col-xl-4, &.col-xl-5, &.col-xl-6, &.col-xl-7, &.col-xl-8, &.col-xl-9, &.col-xl-auto {
        padding-left: 5px;
        padding-right: 5px;
        &:last-child {
          padding-right: 0;
        }
        &:first-child {
          // padding-left: 0;
        }
      }
    }
  }

  a.text-truncate {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    width: 100%;
  }

  div.admin-options {
    margin: 5px;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 5px;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    overflow: auto
  }

  div.admin-table {
    text-align: center;

    a {
      color: $blue-dark;
      &:hover {
        color: $base;
      }
    }

    h4 {
      margin: unset;
      padding: 4px;

      font-size: medium;

      font-family: $font-codeforces;
      background-color: $background-gray;
    }
    th, td {
      font-size: 14px;
      font-family: $font-codeforces;
      vertical-align: middle;
    }
    table {
      margin: unset;
    }

    div.table-footer {
      margin: 10px;

      hr {
        margin: unset;
      }
      height: 16px;
      text-align: right;
    }
  }

  .accordion {
    width: 100%;

    .accordion-item {
      border: solid 1px $background-gray;
      margin: 2px 0;

      .accordion-header {
        margin: unset;
        color: $base !important;
        font-size: 14px;
      }
      button {
        width: 100%;
        text-align: left;
        border: unset;
        background-color: $background-gray;
      }
      p, ul {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

    .accordion-button {
      width: 100%;
      color: $base;
      &::before {
        content: "•";
        padding-right: 1rem;
      }
      &::after {
        content: '+';
        float: right;
      }
    }
    .accordion-button:not(.collapsed)::after {
      content: 'x';
      float: right;
    }
  }

  // ===========
  a.qm-clarify {
    font-weight: bold;
    color: $base !important;
    display: inline-flex;
    align-items: center;
  }
}
