div.problem-detail-pdf-container {
    * { 
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar  {
            display: none;  /* Safari and Chrome */
        }
    }

    height: 40rem;
    @media (max-width: 567px) {
        height: 25rem;
    }

    .rpv-core__viewer {
        overflow: scroll;
    }

    .rpv-toolbar__label {
        display: inline;
        white-space: pre;
    }
    .rpv-toolbar__left {
        display: hidden;
    }

    // .rpv-default-layout__sidebar, .rpv-default-layout__sidebar-ltr {
    //     overflow-x: scroll;
    // }
    .rpv-default-layout__toolbar {
        overflow-x: scroll;
    }
    div.rpv-core__display--hidden {
        display: inline-flex;
    }
    @media (max-width: 767px) {
        div[aria-describedby='rpv-core__tooltip-body-page-navigation-previous'],
        div[aria-describedby='rpv-core__tooltip-body-page-navigation-next'],
        div[aria-describedby='rpv-core__tooltip-body-theme-switch'] {
            display: none;
        }
    }
    

    div[aria-describedby='rpv-core__tooltip-body-default-layout-sidebar-tab-1'],
    div[aria-describedby='rpv-core__tooltip-body-default-layout-sidebar-tab-2'],
    div[aria-describedby='rpv-core__tooltip-body-search-popover'],
    div[aria-describedby='rpv-core__tooltip-body-print'],
    div[aria-describedby='rpv-core__tooltip-body-open'],
    div[aria-describedby='rpv-core__tooltip-body-toolbar-more-actions']
    {
        display: none
    }

    .rpv-thumbnail__list {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-flow: column wrap;
        height: 100%;
        justify-content: center;
        overflow: auto;
    }
}