@import 'global.scss';

.contest-table {
  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4 {
    margin: unset;
    padding: 4px;

    font-size: medium;

    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;
  }
  td.participate-options {
    min-width: 180px;
  }

  td.participate-options {
    a {
      color: $blue-dark;
      &:hover {
        color: darkmagenta;
      }
      text-decoration: underline;
      font-size: 12px;
    }
    min-width: 180px;
  }

  table {
    margin: unset;
    tbody {
      tr {
        height: 4rem;
      }
    }
  }

  span.contest-status-lbl {
    font-size: 12px;
  }

  span.active-continue-label {
    background-color: $red-lighter;
    padding: 0 5px;
  }

  .contest-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;

  }
}
