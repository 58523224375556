@import 'global.scss';

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  // border: solid 2px $base;
  // border-radius: 3px;
  padding-top: 30px;
  padding-bottom: 30px;

  min-height: 55vh;

	background: linear-gradient(to bottom,
    $background-gray 0%, $background-gray 10%,
    $sub 60%, $sub 100%
  );
  text-align: center;
  background-repeat:space;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    max-width: 1400px;
  }


  @media (max-width: 1500px){
    .container {
      max-width: 1400px;
      width: 98%;
    }
  }
}

.react-icons {
  // vertical-align: middle;
  margin-right: 5px;
}
