@import "global.scss";

.subheader {
    // Top Border Line
    // border-top: solid 1px $background-gray-dark;
    background-color: $background-gray;
    font-family: $font-base;
    // font-weight: bold;

    width: 100%;

    height: 24px;

    text-align: left;
    font-size: 14px;
    color: $background-gray-darker;

    .float-left {
        display: inline-flex;
        .left-padder {
            width: 115px;
            text-align: center;
            font-weight: bold;
            // padding-left: 50px;
            // padding-right: 50px;
            // font-size: 9px !important;
            // vertical-align: middle;
        }
        span,div {
            margin: auto;
            margin-right: 10px;
        }
    }

    .float-right {
        display: inline-flex;
        span,div {
            margin: auto;
            // margin: 0 5px;
            // &:last-child {
            //     margin-right: 0;
            // }
            // vertical-align: middle;
        }
    }

    // Subcomponenets css
    .org-display {
        margin: auto;

        display: flex;
        width: min-content;
        align-items: center;
        padding-bottom: 1px;
        height: 24px;
        // &.org-uni {
        //     // color: $red-light;
        //     color: $base;
        // }
        // &.org-none {
        //     color: $base;
        // }
    }

    // Bottom Border Line
    border-bottom: solid 1px $background-gray-dark;
}
