@import 'global.scss';

div#contest-standing {
  table {
    margin: unset;
    table-layout: fixed;
  }

  th,
  td {
    vertical-align: middle;
  }

  th.th-rank,
  td.td-rank {
    width: 100px;
  }

  th.th-participant,
  td.td-participant {
    width: 300px;
  }

  td.td-participant {
    padding-left: 10px;
    padding-right: 10px;
    width: 300px;
  }

  th.th-score,
  td.td-score {
    width: 100px !important;
    overflow: hidden;
  }

  th.th-p-best,
  td.td-p-best {
    height: 50px;

    padding: 0;
    margin: 0;
    width: 70px !important;
    overflow: hidden;
  }

  td.td-p-best>div.points-container {
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;

    &.frozen {
      background-color: $blue-light-transparent;
    }

    &.full-points {
      background-color: $green-light-transparent;
    }
    &.fastest {
      background-color: $green-transparent;
      font-weight: bold;
    }

    &:hover {
      background: rgba(0, 0, 0, .2);
      background-blend-mode: darken;

      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;

      cursor: pointer;
    }

  }

  div.rank-display {
    column-gap: 5px;
    font-size: 20px;
    font-weight: bold;
  }

  .points {
    font-weight: bold;
    font-size: 1rem;

    &.one-fourth {
      color: $not-accepted;
    }

    &.two-fourth {
      color: $runtime-error;
    }

    &.three-fourth {
      color: $dark-yellow;
    }

    &.four-fourth {
      color: $accepted;
    }

    &.full-points {
      color: $accepted;
      // color: lightgreen;
    }

    span.extra {
      margin-left: 2px;
      font-size: 12px;
      color: black;

      //span.tries {}

      span.frozen_tries {
        color: $blue-dark;
      }
    }
  }

  .time {
    width: 100%;
    overflow: hidden;
    font-size: 0.7rem;
  }

  .standing-lbl {
    .standing-head {
      font-family: $font-codeforces;
      margin: 0;
    }

    span.frozen-time {
      font-family: $font-codeforces;
      font-size: 12px;
    }

    margin-bottom: 5px;

    .standing-options {
      display: flex;
      column-gap: 5px;
    }
  }

  .scroll__margin {
    scroll-margin-top: 50px;
  }
  
  .scroll__highlight {
    border: 3px solid orange;
  }
}