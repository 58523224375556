@import 'global.scss';

ul.admin-nav {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  overflow-anchor: none;

  background-color: $base !important;

  .brand {
    padding: 1rem 0.5rem;

    text-align: center;
    letter-spacing: .05rem;
    text-decoration: none;

    z-index: 1;
    color: $sub !important;

    position: relative;

    img.site-icon {
      max-height: 6rem;
    }
    p.site-icon-text {
      background-color: #000000d6;
      color: $sub;
      display: inline-block;

      font-weight: bold;

      margin: unset;
      padding: unset;
      font-family: $font-monospace;
      font-size: 1.3rem;

      position: absolute;
      top: 50%;
      left: 50%;
      // transform: translate(-50%, -50%) rotate(-30deg);
      // -webkit-transform: translate(-50%, -50%) rotate(-30deg);
      transform: translate(-50%, -40%);
      -webkit-transform: translate(-50%, -40%);
    }
  }
  hr.sidebar-divider {
    border-top: 1px solid $sub;
    margin: 0 1rem;
  }
  .nav-item {
    width: 100%;
    margin: 5px 0;
    // background-color: $base-light !important;

    .nav-link {
      color: $sub !important;
      text-align: center;

      padding: .75rem 0 .75rem 1rem;

      text-decoration: none;
      background-color: transparent;

      display: flex;
      align-items: center;
      column-gap: 10px;

      .icon {
        font-size: 1.3rem;
      }

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 768px) {
        text-align: left;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 3px;

        .icon {
          font-size: 1.6rem;
        }
        font-size: 0.8rem;
      }
    }
  }
  .nav-header-item {
    .nav-link {
      color: $sub !important;
      padding-left: 1rem;
      text-transform: capitalize;
      text-align: left;

      text-decoration: none;
      background-color: transparent;

      &::before {
        content: "<<";
        padding-right: 0.2rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 6rem !important;
  }
  @media (min-width: 769px) {
    min-width: 160px !important;
  }
}
