@import 'global.scss';

.verdict {
  font-weight: 600;
  font-family: $font-codeforces;
  cursor: help;

  &.ac {
    color: $accepted !important;
  }

  &.wa, &.tle, &.mle, &.ole {
    color: $not-accepted !important;
  }

  &.ir, &.rte {
    color: $runtime-error !important;
  }

  &.qu, &.g, &.p, &.sc {
    color: $queued !important;
  }

  &.ie, &.ce {
    color: $system-error !important;
  }
  &.fr {
    color: $blue-dark !important;
    background-color: $blue-light-transparent;
  }

  span.points {
    margin-left: 2px;
  }

  @media (min-width: 768px) {
    .verdict-wrapper {
      span.text { display: none; }
      &.ac::after {
        content: "Accepted"
      }
      &.wa::after {
        content: "Wrong Answer"
      }
      &.tle::after {
        content: "Time Limit Exceeded"
      }
      &.mle::after {
        content: "Memory Limit Exceeded"
      }
      &.ole::after {
        content: "Output Limit Exceeded"
      }
      &.ir::after {
        content: "Invalid Return"
      }
      &.rte::after {
        content: "Runtime Error"
      }
      &.qu::after {
        content: "Waiting.."
      }
      &.p::after {
        content: "Processing.."
      }
      &.g::after {
        content: "Judging.."
      }
      &.sc::after {
        content: "Skipped"
      }
      &.ie::after {
        content: "Internal Error"
      }
      &.ce::after {
        content: "Compile Error"
      }
      &.fr::after {
        content: "Frozen"
      }
    }
  }
}
