@import "global.scss";

.sign-in-form {
    border: solid 2px ;
    background-color: $sub;
    max-width: 600px;

    .row {
        text-align: left;
    }

    .submit-btn {
        // background-color: $base;
        background-color: $sub;
        color: $base;
    }

    width: 80%;
    margin: auto;

    margin: 20px;
    padding: 20px;
}