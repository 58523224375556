@import 'global.scss';

.main-component {
  padding: unset;
}

.list-sidebar-wrapper {
  width: 100%;

  .side-bar {
    display: flex;
    flex-direction: column;
    padding-left: 0;

    .side-component {
      margin: 10px 0;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-height: 50px;

      div, span, p, h1, h2, h3, h4, h5, h6, a {
        width: 100%;
        color: $base;
        word-break: break-word;
        flex: 1;
        font-family: $font-codeforces;
      }


      h4, h5 {
        margin: unset;
        padding: 6px;
        // text-decoration: underline;
        background: $background-gray;
        flex: 0;
        color: $base;
        font-size: 16px;
        font-weight: bold;
      }

      span {
        color: $blue-dark;
      }
    }
  }

  .offcanvas {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1100;
    top: 0;
    right: 0;

    background-color: $base;
    overflow: hidden;

    transition: 0.3s;
    padding-top: 20px;

    // a {
    //   padding: 8px;
    //   text-decoration: none;
    //   font-size: 20px;
    //   display: block;
    //   transition: .3s;
    //   color: $sub-dark;

    //   &:hover {
    //     color: $sub;
    //   }
    // }

    .offcanvasCloseBtn-wrapper {
      position: absolute;
      right: 30px;
      bottom: 30px;

      .offcanvasCloseBtn {
        z-index: 1200;

        font-size: 24px;
        line-height: 24px;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 50px;
        width: 50px;

        color: $sub !important;
        border: solid 4px $sub !important;
        border-radius: 50% !important;
      }
    }
  }
  .offcanvas-menu {
    transition: margin-right .3s;
    // padding: 16px;

    .offcanvasOpenBtn-wrapper {
      position: absolute;

      .offcanvasOpenBtn {
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 1040;

        font-size: 18px;
        line-height: 14px;

        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 50px;
        width: 50px;

        color: $sub !important;
        border: solid 4px $sub !important;
        border-radius: 50% !important;
      }

    }

  }

  @media screen and (max-height: 450px) {
    .offcanvas {padding-top: 34px;}
    .offcanvas a {font-size: 16px;}
    .offcanvas .canvasCloseBtn {font-size: 24px;}
  }

  .main-component {
    // background-color: $sub;
    // border: solid 2px $base;
    // height: 100%;
  }

  .offcanvas .side-component {
    margin: 15px;
    min-height: 150px;
  }
}
