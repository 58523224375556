@import 'global.scss';

div.admin.problem-panel {
  h1, h2, h3, h4, h5, hr{
    margin: unset;
  }

  h3, h4, h5 {
    margin: 4px;
  }

  .panel-header {
    display: inline-flex;
    width: 100%;

    span.title-text {
      flex-grow: 1;
    }

    column-gap: 10px;
    align-items: center;
  }

  .col {
    text-align: left;
  }

}
