@import 'global.scss';

div.problem-info {
  h4 {
    margin: 5px 0;
  }
  hr {
    margin: unset;
    padding: unset;
  }
  div.problem-details {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    ul {
      margin: unset;
      padding: unset;

      list-style-type: none;
      li {
        text-align: left;
        strong {
          padding-right: 5px;
        }
      }
    }

    .options {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;

      .btn {
        display: flex;
        align-items: center;

        margin-top: 2px;
        margin-bottom: 2px;
        padding: 0 4px;
        font-size: 14px;
        color: $blue-dark;

        width: fit-content;
        text-align: right;
        border: solid 1px $base;
        border-radius: 5px;
        background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(240, 240, 240) 70%, rgb(228, 228, 228) 100%)
      }
    }
  }

  div.problem-statement {
    min-height: 200px;
    div.problem-pdf {
      width: 95%;
      text-align: center;
      margin: auto;
      border: solid 2px $background-gray;
      margin-top: 5px;
    }
    div.problem-text {
      padding: 3px 5px;
      .wmde-markdown.wmde-markdown-color,
      .w-md-editor-show-preview {
        margin: unset;
        padding: unset;
        text-align: left;
        border: solid 1px $background-gray;
        border-radius: 3;
        box-shadow: unset;
        background-color: unset;
      }
      .wmde-markdown.wmde-markdown-color.w-md-editor-preview {
        padding: 5px 10px;
      }
      .w-md-editor-content {
        border: unset;
      }
    }
  }
}
