@import "global.scss";

.header {
  .site-logo {
    float: left;
    position: absolute;
    z-index: 1040;
    img {
      width: 120px;
      margin-left: -6px;
    }
  }

  background-color: $base;
  color: $sub;

  .container {
    height: 40px;
    // margin: auto;
    vertical-align: middle;
    position: relative;

    text-align: right;
    font-size: x-small;

    span {
      padding: 0 10px;
      border-left: solid 1px $background-gray;

      line-height: 38px;

      &.bugs {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
}
