@import 'global.scss';

.submit-modal {
  .modal-dialog {
    height: 80vh;

    .modal-content {
      border: solid 2px $base;
      // background-color: $sub;

      .modal-header {
        padding: 5px;
        padding-left: 15px;
        .modal-title {
          color: $base;
        }

        .btn-close {
          height: 10px;
          width: 10px;
        }
      }

      .modal-footer {
        @import "styles/SubmissionVerdict.scss";
        .note {
          display: flex;
          align-items: center;
          column-gap: 5px;

          span.warning {
            font-size: 10px;
            flex: 1;
          }
          span {
            font-size: 14px;
            flex: 1;
          }

          text-align: left;
          font-family: $font-monospace;
          color: $red-light;
          width: 100%;
        }
        padding: 5px;
        padding-left: 15px;
      }
    }
  }
}
