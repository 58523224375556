@import "global.scss";

.error-box {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    padding: 2px 8px;

    border: solid 1px $red;
    background-color: $red-lighter;

    text-align: left;

    * {
        margin: 0;
        font-size: 12px;
        color: $red-light;
    }
    font-family: $font-subscript;

    div.errors-general-text {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin: 5px 0;
        padding: 3px;
    }
    p {
        font-weight: 100;
    }
    .error-sub-title {
        font-size: 14px;
        font-weight: bold;
        font-family: $font-subscript;
        color: $red-light;
        background-color: unset;
    }
}
