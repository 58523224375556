@import 'global.scss';

span.classic-pagination {
  font-family: $font-codeforces;
  font-size: 14px;
  // display: inline-block;
  ul {
    display: inline;
    list-style-type: none;
    text-align: right;
    margin: unset;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: unset;

    li {
      display: inline;

      &.next, &.previous {
        display: none;
      }

      &.selected {
        color: $base;
        font-weight: bold;
        &.selected:hover a {
          text-decoration: none;
          cursor: default;
        }
      }

      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
