@import 'global.scss';

.org-table {
  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4 {
    margin: unset;
    padding: 4px;

    font-size: medium;

    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  table {
    margin: unset;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;
  }

  td.org-i {
    position: relative;

    div.org-img-wrapper {
      max-height: 50px;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        max-height:50px;
        // max-width:230px;
        width: auto;
        height: auto;
      }
    }
    span.org-slug {
      font-weight: bold;
    }
  }

  .org-tag {
    display: flex;
    justify-items: center;
    align-items: center;
    column-gap: 3px;

    margin: 0 2px;

    padding: 1px 3px;
    border: solid 2px $background-gray;
    border-radius: 10px;
  }

  .icons {
    height: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }

  .org-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;

  }
}
