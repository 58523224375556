@import "global.scss";

$spike-percent: 5%;

.footer {
    min-height: 200px;
    background-color: $base;

    /* Using Pattern Image */
    // background-image: url('/assets/patterns/footer-bg.png');
    // background-position: left top;
    // background-repeat: repeat-x;

    /* Using raw CSS */
    background-image:
       /* Top jagged */
    linear-gradient(135deg, $sub $spike-percent, transparent $spike-percent),
    linear-gradient(225deg, $sub $spike-percent, transparent $spike-percent),
    /* Bottom jagged */
    linear-gradient(45deg, $base $spike-percent, transparent $spike-percent),
    linear-gradient(-45deg, $base $spike-percent, transparent $spike-percent);

  background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;

  background-size: 20px;
  background-repeat: repeat-x;

    padding-top: 30px;

    color: $sub;

    .container {
        margin-top: 15px;

        p.bkdn-uni-container {
            width: 100%;
            text-align: center;
            img.bkdn-uni-flag {
                max-height: 150px;
                max-width: 100%;
            }
        }

        .col {
            // border: solid 2px blue;
            margin: auto;
        }

        .link-section {
            div.subcategory {
                margin-top: 18px;
                border-top: 2px solid $background-gray-dark;
                position: relative;
            }

            h4 {
                position: absolute;
                padding: 2px 20px 0 0;
                top: -10px;
                // left: 14px;

                font-size: small;
                font-family: $font-section-headline;
                font-weight: bold;

                color: $background-gray-dark;
                line-height: 100%;

                background-color: $base;
                // border: 2px solid red;

                &::before {
                    content: "•";
                    padding-right: 10px;
                }
            }
            p {
                margin: 12px 10px;
                padding-top: 1px;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: 16px;
                row-gap: 4px;

                a {
                    font-size: 12px;
                    white-space: nowrap;
                    // color: $sub;
                    color: $background-gray-dark;
                    padding-right: 10px;
                    &::before {
                        content: ">";
                        padding-right: 5px;
                    }
                }
            }
        }

        .lower-row {
            border-top: solid 1px $background-gray-dark;
            margin-top: 5px;
            padding-top: 5px;

            pre#footer-note {
                color: $background-gray-dark;
                white-space: pre-line;
                text-align: center;
                font-size: small;
                // margin: auto;
                margin: 4px;
            }
        }
    }
}
