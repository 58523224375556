@import 'global.scss';

.main-component {
  padding: unset;
}

.one-column-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .main-component {
    background-color: $sub;
    border: solid 2px $base;
    // height: 100%;
  }
}
