@import "global.scss";

.scroll-top-btn {
    position: fixed;
    bottom: 5px;
    left: 5px;
    z-index: 1200;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}

.scroll-top-btn__bounce_animation {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bounce;
    animation-timing-function: linear;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}
