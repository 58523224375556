$base: #1c2237;
$base-light: #e0e7ff;

$sub-dark: rgb(200, 200, 200);
$sub: rgb(245, 245, 245);
$sub-bright: white;


$background-gray: #d6d6d6;
$background-gray-dark: #c5c5c5;
$background-gray-darker: #505050;

$red: rgb(187, 0, 0);
$red-light: rgb(185, 62, 62);
$red-lighter: #FAE1E2;

$blue-dark: darkblue;
$blue-light: rgb(190, 239, 245);
$blue-light-transparent: rgba(190, 239, 245, 0.8);
$blue-lighter: #F0F9FA;

$green-light: rgb(173, 255, 200);
$green-light-transparent: rgba(173, 255, 200, 0.5);
$green-transparent: rgb(83, 241, 102);

$orange-lighter: #FADFC5;

$dark-yellow: rgb(196, 199, 0);

$accepted: green;
$not-accepted: rgba(255, 0, 0, 0.856);
$runtime-error: orange;
$queued: darkgray;
$system-error: black;


@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&family=Roboto+Mono:wght@300&display=swap');
// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
// font-family: 'Roboto Mono', monospace;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');
// font-family: 'Open Sans', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&display=swap');
// font-family: 'Oswald', sans-serif;

$font-brand: 'Open Sans', sans-serif;
$font-monospace: 'Roboto Mono', monospace;
$font-subscript: 'Courier New', Courier, monospace;
$font-section-headline: 'Droid Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-codeforces: "verdana", "arial", "sans-serif";
$font-base: apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
