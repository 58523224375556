@import 'global.scss';

div.submission-info {
  @import 'styles/SubmissionVerdict.scss';

  a { color: $blue-dark }
  h4 { margin: 5px 0; }
  hr { 
    margin: unset;
    padding: unset;
  }
  div.row {
    margin: unset;
    display: block;
  }

  div.submission-details {
    padding-left: 5px;
    padding-right: 5px;
    h5 {
      display: inline;
    }

    div.general {
      .row {
        display: flex;
        white-space: pre;
        span {
          width: fit-content;
          white-space: pre;
          strong {
            padding-right: 10px;
          }
        }
      }
    }

    div.source {
      .ace_editor {
        margin: 5px 0;
        border: solid 1px $base;
      }
    }

    div.info-subsection {
      margin: 5px 0;
      padding: 5px 10px;
      border-bottom: solid 1px $background-gray;
      &:last-child {
        border-bottom: unset;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 5px;
    td {
      border-top: unset;
      padding: unset;
    }
  }
}