@import 'global.scss';

.submit-form {
  label {
    margin: unset;
  }

  div.select-div {
    display: inline-flex;
    column-gap: 15px;

    select {
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 6px;
      font-size: 12px;
      font-family: $font-codeforces;
    }
  }
}