@import 'global.scss';

div#recent-submission-sidebar {
  div.table-responsive {
  }

  table {
    overflow-y: scroll;
    td {
      font-weight: 100;
    }
    th, td {
      width: auto;
      font-size: 14px;
      vertical-align: middle;
      word-break: keep-all;

      &.responsive-date {
        div.date {
          font-size: 10px;
          word-break: keep-all;
        }
        div.time {
          font-size: 12px;
          word-break: keep-all;
        }
        width: fit-content;
      }
    }

    overflow: hidden !important;

    margin: 0; padding: 0;
  }

  td.info {
    padding: 0 0.3rem;
    width: fit-content;

    .prob-wrapper {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;

      .prob {
        font-weight: bold;
        font-size: 14px;
        max-width: fit-content;
      }

      a#sub-id {
        color: black;
        font-size: 14px;
        font-weight: bold;
        max-width: max-content;
      }
    }

    .other-wrapper {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;

      font-size: 12px;

      div.sub-wrapper {
        display: inline-flex;
        flex-direction: row;

        column-gap: 5px;
        max-width: max-content;
        padding: 5px;
      }

      div.lang-wrapper {
        font-size: 10px;
      }

      div.text-wrapper {
        width: fit-content;
      }

      div.points-wrapper {
        max-width: 50px;
        min-width: 20px;
      }

      @import 'styles/SubmissionVerdict.scss';
    }
  }

  td.result {
    margin: 0; padding: 0;
  }
  // .verdict {
  //   font-weight: 300;
  //   font-family: $font-codeforces;

  //   &.ac > span {
  //     color: $accepted !important;
  //   }

  //   &.wa > span, &.tle > span, &.mle > span, &.ole > span {
  //     color: $not-accepted !important;
  //   }

  //   &.ir > span, &.rte > span {
  //     color: $runtime-error !important;
  //   }

  //   &.qu > span, &.g > span, &.p > span, &.sc > span {
  //     color: $queued !important;
  //   }

  //   &.ie > span, &.ce > span {
  //     color: $system-error !important;
  //   }
  // }
}
