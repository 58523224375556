@import 'global.scss';

.problem-table {
  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4, h5 {
    margin: unset;
    padding: 4px;

    font-size: medium;
    font-weight: bold;
    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;

    &.problem-code {
      min-width: fit-content;
      max-width: 50px;
    }
    &.problem-title {
      min-width: fit-content;
      max-width: 100px;
    }
  }
  table {
    margin: unset;
  }

  .problem-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;

  }
}
