@import "global.scss";

.sticky-header {
    top: 0;
    position: sticky;
    z-index: 1000;

    .navbar {
        background-color: $sub !important;
        // top: 0;
        // position: sticky;
        white-space: nowrap;

        .container {
            height: 100%;

            .navbar-brand {
                font-family: $font-brand;
                font-weight: bold;

                display: flex;
                align-items: center;

                height: 45px;
                width: 100px;


                img {
                    height: 60%;
                    width: auto;

                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            //
            .navbar-user-auth {
                flex: 1;
                .nav-link {
                    margin-right: 5px;
                    border-radius: 4px;
                    line-height: 10px;
                    border: solid 1px $base;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(240, 240, 240, 1) 70%,
                        rgb(228, 228, 228) 100%
                    );

                    color: $base;

                    padding-left: 7px;
                    padding-right: 7px;

                    &#fake {
                        margin-right: 5px;
                        border: solid 1px $sub;
                        background: $sub !important;
                    }
                }

                .dropdown-menu {
                    position: absolute;
                    left: -450%;
                }
            }
            button#switch-org-btn {
                margin-right: 5px;
                border-radius: 4px;
                line-height: 10px;
                border: solid 1px $base;
                background: rgb(255, 255, 255);
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(240, 240, 240, 1) 70%,
                    rgb(228, 228, 228) 100%
                );

                color: $base;
                cursor: pointer;

                padding-left: 5px;
                padding-right: 5px;
            }
        }

        // Site Brand
        #brand {
            color: $base;
            text-shadow: 0px 0.4px 0.2px #d2d2d2;
        }

        // Nav items
        .nav-item,
        .nav-link,
        .nav-link-fake {
            font-family: $font-brand;
            // text-decoration: underline;
            text-transform: capitalize;
            font-size: 14px;

            .dropdown-item {
                font-size: 12px;
            }

            &:focus {
                font-weight: 600;
            }
        }

        // Bottom Separate Line
        border-top: solid 1px $sub;
        border-bottom: solid 1px $background-gray-dark;
    }
}
