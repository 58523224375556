@import 'global.scss';

div.admin.submission-panel {
  h1, h2, h3, h4, h5, hr{
    margin: unset;
  }

  h3, h4, h5 {
    margin: 4px;
  }

  .panel-header {
    display: inline-flex;
    width: 100%;
    
    span.title-text {
      flex-grow: 1;
    }

    column-gap: 10px;
    align-items: center;
  }

  .col {
    text-align: left;
  }

  .accordion-item {
    border: solid 1px $background-gray;
    margin: 2px 0;

    h2 {
      color: $base !important;
      font-size: 14px;
    }
    button {
      width: 100%;
      text-align: left;
      border: unset;
      background-color: $background-gray;
    }
  }

  .accordion-button {
    color: $base;
    &::before {
      content: "•";
      padding-right: 1rem;
    }
    &::after {
      content: '+';
      float: right;
    }
  }
  .accordion-button:not(.collapsed)::after {
    content: 'x';
    float: right;
  }
}
