@import 'global.scss';

.judge-table {
  a {
    color: $blue-dark;
    &:hover {
      color: $base;
    }
  }

  h4 {
    margin: unset;
    padding: 4px;

    font-size: medium;

    font-family: $font-codeforces;
    background-color: $background-gray;
  }
  th, td {
    font-size: 14px;
    font-family: $font-codeforces;
    vertical-align: middle;
  }
  .icons {
    height: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }
  table {
    margin: unset;
  }

  .judge-table-footer {
    margin: 10px;

    hr {
      margin: unset;
    }
    height: 16px;
    text-align: right;

  }
}
