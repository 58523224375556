@import "global.scss";

.content-div {
    width: 80vw;
    min-height: 50vh;
    padding-top: 50px;
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // border: solid 4px $base;
    border-radius: 10%;
    // background-color: $sub-dark;
	// background: linear-gradient(to bottom,
    //     $sub 0%, $sub-dark 15%, $sub-dark 85%, $sub 100%
    // );

    text-align: center;

    color: $base;
}
div.img-label {
    font-family: $font-codeforces;
    font-weight: bold;
    font-size: medium;
    color: $base;
}
span.code-markup {
    font-family: $font-monospace;
    color: $red;
    background-color: $red-lighter;
    border-radius: 15%;
    padding: 0px 2px;
}
span.subtext {
    font-family: $font-codeforces;
    font-size: 14px;
}
.title {
    display:block;
    margin:0;
    padding:0;
    text-align:center
}
.title h5 {
    overflow: hidden;
    text-align: center;
    position:relative;
    display:block;
    margin:0;
    padding:0;
}
.title h5:before, .title h5:after {
    content:'\a0';
    display:inline-block;
    width: 8%;
    height:2px;
    background-color:$base;
    line-height:0;
    margin:0 5px;
}
